import React, { Suspense, useEffect, useRef, useState } from 'react'
import { ComponentLoader } from '../../common/LoaderFull/ComponentLoader'
import { getUsersWithFilter } from '../../../../redux/usersReducer'
import {
  actionsOrder,
  getOrderFromServer,
  getOrdersFromServerDashboardArchive,
  startOrdersListeningForDashboard
} from '../../../../redux/ordersReducer'
import { useDispatch, useSelector } from 'react-redux'
import { OrderType } from '../../../../types/types'
import { appActions } from '../../../../redux/appReducer'
import { AppStateType } from '../../../../redux/rootReducer'
import s from './archive.module.scss'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import OrderView from '../Production/OrderView/OrderView'

const OrderInfo = React.lazy(() => import('../Production/OrderInfo/OrderInfo'))

const Archive = () => {
  const orders = useSelector((state: AppStateType) => state?.orders?.ordersDashboardArchive?.results)
  const nextPage = useSelector((state: AppStateType) => state?.orders.ordersDashboardArchive.next)
  const pageStore = useSelector((state: AppStateType) => state?.orders.pageDashboardArchive)
  const [ordersSortDate, setOrdersSortDate] = useState<Array<OrderType>>([])
  const [searchValue, setSearchValue] = useState('')
  const [fetching, setFetching] = useState(true)
  const nameRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUsersWithFilter('role__type=worker&status=active'))
    dispatch(startOrdersListeningForDashboard())
  }, [dispatch])

  // сортировка заказов по дате завершения
  useEffect(() => {
    if (orders.length) {
      setOrdersSortDate(orders
        .filter(order => order.status === 6 && order.end_datetime)
        .sort((a, b) => {
          const dateFirst = a.end_datetime?.split('.').reverse().join(', ')
          const dateSecond = b.end_datetime?.split('.').reverse().join(', ')

          const newDateA = new Date(dateFirst)
          const newDateB = new Date(dateSecond)
          return +newDateB - +newDateA
        })
      )
    }
  }, [orders])

  // подгрузка заказов
  useEffect(() => {
    if (ordersSortDate.length < 50) {
      setFetching(true)
    }
  }, [ordersSortDate])
  useEffect(() => {
    if (!fetching) return

    if (fetching && nextPage !== null && nextPage.indexOf(`&page=${pageStore + 1}`)) {
      const code = searchValue ? `&code__icontains=${searchValue}` : ''
      dispatch(getOrdersFromServerDashboardArchive('5',
        `&have_tasks=true&dashboard=admin&page=${pageStore}&order=-end_datetime${code}`,
        () => {
          setFetching(false)
          dispatch(actionsOrder.setPageDashboardArchive(pageStore + 1))
          dispatch(appActions.showLoader(false))
        })
      )
    }
    // eslint-disable-next-line
  }, [fetching])
  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getOrdersFromServerDashboardArchive('5',
      `&have_tasks=true&dashboard=admin&page=1&order=-end_datetime&code__icontains=${searchValue}`,
      () => {
        setFetching(false)
        dispatch(actionsOrder.setPageDashboardArchive(pageStore + 1))
        dispatch(appActions.showLoader(false))
      })
    )

    if (searchValue) {
      document.addEventListener('keyup', onClickKey)
    } else {
      document.removeEventListener('keyup', onClickKey)
    }

    return () => document.removeEventListener('keyup', onClickKey)
    // eslint-disable-next-line
  }, [searchValue])

  const scrollHandler = (e: any) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }

  const showOrder = (order: any) => {
    dispatch(getOrderFromServer(order.id))
  }

  const startSearch = () => {
    if (nameRef.current && nameRef.current.value !== searchValue) {
      dispatch(actionsOrder.setResetOrdersDashboardArchive())
      setSearchValue(nameRef.current.value)
    }
  }
  const onClickKey = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && nameRef) {
      startSearch()
    }
  }

  const onSearchOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      dispatch(actionsOrder.setPageDashboardArchive(1))
    }
  }
  const clearSearchName = () => {
    setSearchValue('')
    // @ts-ignore
    nameRef.current.value = ''
  }

  useOnClickOutside(nameRef, startSearch)

  return (
    <div>
      <div className={s.searchBlock}>
        <div className={s.filterName}>
          <input type="text"
                 name="name"
                 className={s.searchInput}
                 onChange={onSearchOrder}
                 placeholder={'Поиск'}
                 ref={nameRef}
          />
          {
            nameRef?.current && nameRef.current.value &&
            <span
              onClick={clearSearchName}
              className={s.close}
            >
              X
            </span>
          }
        </div>
      </div>

      <div className={s.header}>
        <span>Статус</span>
        <span>Закройщик</span>
        <span>Швея</span>
        <span>Распиловщик</span>
        <span>Сборщик</span>
        <span>Резчик</span>
        <span>Поролонщик</span>
        <span>Обивщик</span>
        <span>ОТК</span>
      </div>

      <Suspense fallback={<ComponentLoader/>}>
        {
          ordersSortDate.map((item: any, index: number) => <OrderInfo key={index}
                                                                      order={item}
                                                                      type={'circle'}
                                                                      showOrder={showOrder}/>)
        }
      </Suspense>

      <OrderView/>
    </div>
  )
}

export default Archive